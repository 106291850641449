<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contatti</li>
            </ul>
            <h2>Contattaci</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://maps.google.com/maps?q=ADAP%20Via%20Mattia%20Preti,%201d%20Reggio%20Calabria&t=k&z=19&ie=UTF8&iwloc=&output=embed"></iframe>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Chi siamo</span>
                    <h2>Il Migliore Ente di Formazione Accreditato e Certificato</h2>
                    <p>
                        Una scuola di formazione e qualificazione professionale di eccellenza, che offre specializzazioni in ogni campo, con docenti altamente qualificati, stage e opportunità di lavoro concrete grazie al contatto diretto con le aziende: ADAP, l’Accademia delle Arti e Professioni, con le sue tre sedi a Cosenza, Catanzaro e Reggio Calabria, rappresenta un punto di riferimento altamente specializzato per chi cerca una scuola di formazione in Calabria.

L’Accademia delle Arti e Professioni vanta al proprio attivo un’esperienza di oltre 25 anni nel settore della formazione professionale, ponendosi al servizio di chi vuole prepararsi per affrontare il mondo del lavoro nelle occupazioni più richieste con competenza e professionalità.
                    </p>
                    <p>
                        ADAP è una scuola di formazione privata che opera la propria attività in tre aree principali: estetica e benessere (corso per parrucchiere-acconciatore, estetista o make-up artist), socio-sanitaria e socio educativa (corsi per operatore socio-sanitario, tecnico dell’animazione socio-educativa, responsabile struttura socio-sanitaria) e design, grafica, comunicazione (corsi di tecnico delle rappresentazioni grafiche di messaggi pubblicitari, grafica 3D, game designer, tecnico della progettazione di mobili e complementi).
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">dalla formazione alla professione</span>
                    <h2>Cosa abbiamo di Speciale?</h2>
                    <p>
                        La nostra scuola di formazione in Calabria ha tre diverse sedi dislocate sul territorio calabrese: a COSENZA, in Via Reggio Calabria, 12, con circa 700 mq di superficie a disposizione e 10 aule didattiche, a CATANZARO in Via Lucrezia della Valle, con circa 400 mq di superficie e 6 aule didattiche, e a REGGIO CALABRIA, in Via Mattia Preti, 1d, con 400 mq e 6 aule didattiche.
                    </p>
                    <p>
                        L’Accademia delle Arti e Professioni è accreditata dalla Regione Calabria; i suoi corsi sono certificati ISO 9001 e sono riconosciuti in tutta l’Unione Europea. Enti certificatori controllano periodicamente l’operato e la qualità delle attività e dei corsi, per garantire massima attenzione e professionalità nei confronti degli allievi.
                    </p>
                    <p>
                        Adeguate risorse interne – sotto forma di comitato scientifico – si occupano di seguire con la giusta severità tutto l’iter necessario per fornire un servizio mirato ed efficiente, in linea con le più esigenti aspettative.
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Mettiti in contatto</h2>
                    <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <p>Via Mattia Preti, 1d<br>Reggio Calabria</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <p>Via Reggio Calabria, 12<br>Cosenza</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <p> Via Lucrezia della Valle Centro Lucrezia, 1° piano - Catanzaro</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contatti</h3>
                            <p>Reggio Calabria: <a href="tel:+3909651873922">(+39) 0965-1873922</a></p>
                            <p>Cosenza: <a href="tel:+39098421573">(+39) 0984-21573</a></p>
                            <p>Catanzaro: <a href="tel:+390961050966">(+39) 0961-050966</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Siamo disponibili</h3>
                            <p>Lunedì - Venerdì: 09:00 - 20:00</p>
                            <p>Sabato: 08:30 - 13:30</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Come possiamo aiutarti?</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome e Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="scrivi il tuo messaggio..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">il messaggio è obbligatorio</div>
                                </div>
                            </div>
                            <h2 class="mt-3">Quale corso sei interessato?</h2>
                            <p>seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="sceltaCorso" ngModel id="sceltaCorso" #sceltaCorso="ngModel" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['titolo']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
